import itiLogo from 'src/assets/icons/iti-logo.png';
import haryanaGovIcon from 'src/assets/icons/Emblem_of_Haryana.svg.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    const { i18n, t } = useTranslation();

    // Function to change language
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className='w-full bg-white shadow px-2'>
            <div className="container mx-auto w-full flex justify-between items-center py-2">
                {/* Left Logo */}
                <Link to='/'>
                    <img alt="iti-logo" src={itiLogo} className='w-28 object-contain' />
                </Link>

                {/* Title */}
                <h4 className='font-semibold text-center text-lg text-primary'>
                    {t('navbar.title')} {/* Multilingual Title */}
                </h4>

                {/* Right Section: Language Selector */}
                <div className="flex items-center space-x-4">
                    {/* Haryana Government Logo */}
                    <img alt="gov-logo" src={haryanaGovIcon} className='w-10 object-contain' />

                    {/* Language Dropdown */}
                    <select
                        className="border rounded px-2 py-1 text-sm"
                        onChange={(e) => changeLanguage(e.target.value)}
                        value={i18n.language} // Bind the current language
                    >
                        <option value="en">English</option>
                        <option value="hi">हिन्दी</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
