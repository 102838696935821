

export const tradeList = [
    "Turner",
    "Electrician",
    "Welder",
    "Machinist",
    "Mechanic Electric Vehicle",
    "Warehouse Technician",
    "Electronic Mechanic",
    "Mechanic Motor Vehicle",
    "Textile Wet Processing Technician",
    "Physiotherapy Technician",
    "Health Sanitary Inspector",
    "Plumber",
    "Fitter",
].sort()


export const industryList = [
    "The Panipat Co - operative Sugar Mill, Panipat",
    "Surya Shakti Vessels Pvt.Ltd.",
    "Zelio Auto Pvt.Ltd.",
    "Rajasthan Spare House, Hisar",
    "Superhub Komponents Pvt.Ltd.",
    "Srishti Hyundai, Jind Bypass Rohtak",
    "Ch.Devilal Co - op Sugar Mill, Gohana",
    "ABC Impex(P) Ltd.",
    "Kapoor Industries Ltd.",
    "Municipal Corporation, Rohtak",
    "Civil Hospital, Rohtak",
    "Sunflag Global Hospital, Rohtak",
    "We Care Hospital, Rohtak",
    "Govt.Civil Hospital, Gurugram",
    "Enviro Integrated Facility Services Pvt.Ltd.",
    "Sankei Giken India Pvt.Ltd., Bawal",
    "C-279, Nirankari Marg, Block E, JJ Colony Wazirpur, Ashok Vihar, Delhi, 110052"
].sort()

export const itiNameList = [
    "GITI Hisar",
    "GITI (W) Hisar",
    "GITI Hansi",
    "GITI Barwala",
    "GITI Adampur",
    "GITI Nalwa",
    "GITI Sirsa",
    "GITI (W) Sirsa",
    "GITI Nathusari Chopta",
    "GITI Bhoria Khera",
    "GITI Bhirdana",
    "GITI Tohana",
    "GITI Jind",
    "GITI Narwana",
    "GITI Ambala City",
    "GITI Barara at Holi",
    "GITI Bharanpur",
    "GITI Kurukshetra",
    "GITI Pehowa",
    "GITI Kalka at Bitna",
    "GITI Panchkula",
    "GITI Mustafabad",
    "GITI Sadhaura",
    "GITI Yamuna Nagar",
    "GITI Faridabad",
    "GITI (W) Faridabad",
    "GITI Fatehpur Biloch",
    "GITI Ujjina",
    "GITI Hathin",
    "GITI Palwal",
    "GITI Gurugram",
    "GITI (W) Gurugram",
    "GITI Sohna",
    "GITI JIM, Uncha Majra",
    "GITI Narnaul",
    "GITI Kundmanethi",
    "GITI Rewari",
    "GITI Tankri",
    "GITI Karnal",
    "GITI (W) Karnal",
    "GITI Kaithal",
    "GITI Pundari",
    "GITI Bapoli",
    "GITI Panipat",
    "GITI Bhiwani",
    "GITI Tosham",
    "GITI Devrala",
    "GITI Rawaidhi",
    "GITI (W) Charkhi Dadri",
    "GITI Chhara",
    "GITI Asaudah",
    "GITI Bahadurgarh",
    "GITI Jhajjar",
    "GITI Matanhail",
    "GITI Rohtak",
    "GITI Hassangarh",
    "GITI Kiloi",
    "GITI Madina",
    "GITI Meham",
    "GITI Butana",
    "GITI Gohana",
    "GITI Kharkhoda",
    "GITI Rajlugarhi",
    "GITI Sonipat",
    "GITI (W) Sonipat"
].sort()

export const haryanaCities = [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Gurugram",
    "Hisar",
    "Panipat",
    "Rohtak",
    "Karnal",
    "Yamunanagar",
    "Sonipat",
    "Panchkula"
];

export const haryanaDistricts = [
    "Ambala",
    "Hisar",
    "Sirsa",
    "Fatehabad",
    "Jind",
    "Ambala",
    "Kurukshetra",
    "Panchkula",
    "Yamuna Nagar",
    "Faridabad",
    "Nuh",
    "Palwal",
    "Gurugram",
    "Mahendergarh",
    "Rewari",
    "Karnal",
    "Kaithal",
    "Panipat",
    "Bhiwani",
    "Charkhi Dadri",
    "Jhajjar",
    "Rohtak",
    "Sonipat"
];

export const tradeUnits = [
    "Bapoli",
    "Barana",
    "Fatehpur Biloch",
    "Panipat",
    "Sohna"
].sort()