import { useNavigate } from "react-router-dom";
import ButtonIcon from "./custom/ButtonIcon";
import { useTranslation } from 'react-i18next';

const powericon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
    />
  </svg>
);

const Appbar = () => {
  const navigate  = useNavigate();
  const { t } = useTranslation();
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
  };

  return (
    // <div className="bg-primary w-full flex justify-between items-center px-32">
    //   <div className="flex items-center space-x-2">
    //     <span className="text-white text-sm font-medium">Support: </span>
    //     <span className="text-white text-sm font-light">+91 1723587941</span>
    //   </div>

    //   <div className="flex items-center">
    //     <ButtonIcon
    //       onClick={handleLogout}
    //       icon={powericon}
    //       title="Logout"
    //       className="text-white text-sm font-medium hover:bg-white hover:text-primary transition-all duration-300"
    //     />
    //   </div>
    // </div>

    <div className="w-full bg-primary  px-2">
      <div className="container mx-auto w-full flex justify-between items-center py-2 ">
        <div className="px-5 ">
          <span className="text-white text-sm font-medium">Support: </span>
          <a href="tel:+91 1723587941" className="text-white text-sm font-light"> +91 1723587941</a>
        </div>
        <div className="flex items-center ">
          <ButtonIcon
            onClick={handleLogout}
            icon={powericon}
            title={t("itiHome.logout")}
            className="text-white text-sm font-medium hover:bg-white hover:text-primary transition-all duration-300"
          />
        </div>
      </div>
    </div>
  );
};

export default Appbar;
