import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  haryanaCities,
  itiNameList,
  tradeList,
  haryanaDistricts,
} from "src/assets/data/inputselect";
import CurrentLocation from "src/components/currentLocation";
import Button from "src/components/custom/Button";
import Modal from "src/components/modal";
import {
  getAuthHeaders,
  getAuthHeadersFormdata,
  getMethodApiCall,
  postMethodApiCall,
} from "src/services/api";
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook

const TraineeDetails = ({ data }) => {
  const { t } = useTranslation(); // Initialize the translation function
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [details, setDetails] = useState(
    data || JSON.parse(localStorage.getItem("loginedUser"))
  );
  const [industryList, setIndustryList] = useState([]);
  const [targetLocation, setTargetLocation] = useState({
    lat: null,
    lng: null,
  });
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [difference, setDifference] = useState(null);
  const navigate = useNavigate();

  const [fileError, setFileError] = useState("");
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/jpg",
    "image/webp",
    "image/jpg",
  ];
  const maxFileSizeMB = 2; // 2MB

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...details,
      date_of_birth: moment(data?.date_of_birth).format("YYYY-MM-DD"),
    },
  });

  const reportDetails = async () => {
    setLoad(true);
    // let res = await postMethodApiCall(
    //   "/v1/api/trainee/reportInfo",
    //   getAuthHeaders(),
    //   details
    // );
    // if (res?.status === 200 && res?.data?.success) {
    //   await toast.success(t("traineeDetails.reportSuccess"));
    // }
    window.open("https://forms.office.com/r/Mc5s4vxzPT", "_blank");
    setLoad(false);
  };

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    if (distance < 50) {
      return true;
    } else {
      return false;
    }
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  const recordTraineeAttendance = async () => {
    const formData = new FormData();
    if (details?.image_url) {
      formData.append("image_url", details?.image_url[0]); // Append the image file
      formData.append("registration_id", details?.registration_id);
      formData.append("name", details?.name);
      formData.append("name_of_your_ITI", details?.name_of_your_ITI);
      formData.append("name_of_trade", details?.name_of_trade);
      formData.append("batch_of_trade", details?.batch_of_trade);
      formData.append(
        "name_of_industry_partner",
        details?.name_of_industry_partner
      );
      formData.append("latitude", details?.latitude);
      formData.append("status", "Pending");
      formData.append("longitude", details?.longitude);
    }
    // const checkLatitude = 28.6521;
    // const checkLongitude = 77.1665;

    if (
      calculateDistance(
        details?.latitude,
        details?.longitude,
        targetLocation.lat,
        targetLocation.lng
      )
    ) {
      let res = await postMethodApiCall(
        "/v1/api/trainee/attendance/post",
        getAuthHeadersFormdata(),
        formData
      );
      if (res?.status === 201 && res?.data?.success) {
        await toast.success("Attendance Marked Successfully");
        navigate("/attendance-history");
        reset();
      }
    } else {
      await toast.error("Please Mark Your Attendance within 50 meter radius");
    }
  };

  const onSubmit = (data) => {
    if ("geolocation" in navigator) {
      setOpenModal(true);
      navigator.geolocation.getCurrentPosition(function (position) {
        setDetails({
          ...data,
          latitude: String(position.coords.latitude),
          longitude: String(position.coords.longitude),
        });
      });
    } else {
      console.warn("Geolocation is not available in your browser.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileError("");

    // Check if file exists
    if (!file) return;
    if (!allowedFileTypes.includes(file.type)) {
      setFileError(
        "Invalid file type. Please upload a jpg, jpeg, png, or webp image."
      );
      e.target.value = null; // Clear file input
      return;
    }
    if (file.size > maxFileSizeMB * 1024 * 1024) {
      setFileError(
        `File size exceeds ${maxFileSizeMB} MB. Please upload a smaller image.`
      );
      e.target.value = null; // Clear file input
      return;
    }
  };

  const apiKey = "AIzaSyC2Mkdfi-8r579NqAFwczjluMnNM6Xf6Ko";

  const getCoordinatesFromMapbox = async (address) => {
    try {
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${apiKey}`;

      fetch(geocodeUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "OK") {
            const location = data.results[0].geometry.location;
            setTargetLocation({ lat: location.lat, lng: location.lng });
          } else {
            console.error("Geocoding error:", data.status, data.error_message);
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    } catch (err) {
      console.error("Error fetching location from Mapbox:", err);
      return null;
    }
  };

  // useEffect(() => {
  //   getCoordinatesFromMapbox("Govt.Civil Hospital, Gurugram");
  // }, []);
  const getIndustryData = async () => {
    // /mou-details
    let url = `/v1/api/mou-details`;
    let res = await getMethodApiCall(url, getAuthHeaders());
    if (res?.status === 200) {
      let data = [
        ...new Set(
          res.data.data
            .map((item) => item.industry_name) 
            .filter((name) => name && name.trim()) 
        ),
      ].sort();
      data = [...data, "C-279, Nirankari Marg, Block E, JJ Colony Wazirpur, Ashok Vihar, Delhi, 110052"];
    data = [...new Set(data)].sort();
      setIndustryList(data);
    }
  };
  useEffect(() => {
    getIndustryData();
  }, []);

  return (
    <div className="w-full">
      <div className="relative h-full flex justify-center items-center py-3 container mx-auto">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full px-8 py-6 mt-4 text-left bg-white rounded-md shadow-sm"
        >
          <div className="flex items-center justify-between gap-2">
            <p className="m-0 text-[16px] font-semibold text-primary">
              {t("traineeDetails.title")}
            </p>
            <div className="space-x-2 flex flex-col items-center justify-center md:flex-row">
              <Link className="text-sm text-primary" to="/attendance-history">
                {t("traineeDetails.attendanceHistory")}
              </Link>
              <Button
                disabled={load}
                onClick={reportDetails}
                title={
                  load
                    ? t("traineeDetails.processing")
                    : t("traineeDetails.report")
                }
                type="button"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.registrationNumber")}
                <span className="text-red-400">*</span>
              </label>
              <input
                disabled
                {...register("registration_id", {
                  required: t("traineeDetails.registrationIdRequired"),
                })}
                placeholder={t("traineeDetails.registrationNumber")}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              />
              {errors.registration_id && (
                <span className="text-red-500 text-xs">
                  {errors.registration_id.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.fullName")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <input
                disabled
                {...register("name", {
                  required: t("traineeDetails.fullNameRequired"),
                })}
                placeholder={t("traineeDetails.fullName")}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              />
              {errors.name && (
                <span className="text-red-500 text-xs">
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.phoneNumber")}
                <span className="text-red-400">*</span>
              </label>
              <input
                disabled
                {...register("phone_number", {
                  required: t("traineeDetails.phoneNumberRequired"),
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: t("traineeDetails.phoneNumberInvalid"),
                  },
                })}
                placeholder={t("traineeDetails.phoneNumber")}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                type="tel"
              />
              {errors.phone_number && (
                <span className="text-red-500 text-xs">
                  {errors.phone_number.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.gender")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                disabled
                {...register("gender", {
                  required: t("traineeDetails.genderRequired"),
                })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value="">{t("traineeDetails.gender")}</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
              {errors.gender && (
                <span className="text-red-500 text-xs">
                  {errors.gender.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.dateOfBirth")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <input
                disabled
                {...register("date_of_birth", {
                  required: t("traineeDetails.dateOfBirthRequired"),
                })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
                type="date"
              />
              {errors.date_of_birth && (
                <span className="text-red-500 text-xs">
                  {errors.date_of_birth.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.fatherName")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <input
                disabled
                {...register("father_name", {
                  required: t("traineeDetails.fatherNameRequired"),
                })}
                placeholder={t("traineeDetails.fatherName")}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              />
              {errors.father_name && (
                <span className="text-red-500 text-xs">
                  {errors.father_name.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.motherName")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <input
                disabled
                {...register("mother_name", {
                  required: t("traineeDetails.motherNameRequired"),
                })}
                placeholder={t("traineeDetails.motherName")}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              />
              {errors.mother_name && (
                <span className="text-red-500 text-xs">
                  {errors.mother_name.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.courseAffiliation")}
              </label>
              <div className="flex mt-1.5 text-gray-500 gap-2 custom-radio">
                <label className="flex items-center gap-1">
                  <input
                    checked={details?.affiliation_type === "NCVT"}
                    // disabled
                    readOnly
                    type="radio"
                    value="NCVT"
                    // {...register("course_affiliation_", { required: "Please select a course affiliation" })}
                  />
                  NCVT
                </label>
                <label className="flex items-center gap-1">
                  <input
                    readOnly
                    checked={details?.course_affiliation_ === "SCVT"}
                    type="radio"
                    value="SCVT"
                    // {...register("course_affiliation_", { required: "Please select a course affiliation" })}
                  />
                  SCVT
                </label>
              </div>
              {errors.course_affiliation_ && (
                <span className="text-red-500 text-xs">
                  {errors.course_affiliation_.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.selectCity")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                // disabled
                name="city_of_ITI"
                {...register("city_of_ITI", {
                  required: t("traineeDetails.cityRequired"),
                })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value="">{t("traineeDetails.selectCity")}</option>
                {haryanaCities?.map((city, i) => {
                  return (
                    <option key={city + i} value={city}>
                      {city}
                    </option>
                  );
                })}
              </select>
              {errors.city_of_ITI && (
                <span className="text-red-500 text-xs">
                  {errors.city_of_ITI.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.selectDistrict")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                // disabled
                name="district_of_ITI"
                {...register("district_of_ITI", {
                  required: t("traineeDetails.districtRequired"),
                })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value="">{t("traineeDetails.selectDistrict")}</option>
                {haryanaDistricts?.map((district, i) => {
                  return (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  );
                })}
              </select>
              {errors.district_of_ITI && (
                <span className="text-red-500 text-xs">
                  {errors.district_of_ITI.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.selectITI")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                // disabled
                // {...register("name_of_your_ITI", {
                //   required: t("traineeDetails.itiNameRequired"),
                // })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value="">{t("traineeDetails.selectITI")}</option>
                {itiNameList?.map((iti) => {
                  return (
                    <option key={iti} value={iti}>
                      {iti}
                    </option>
                  );
                })}
              </select>
              {errors.itiName && (
                <span className="text-red-500 text-xs">
                  {errors.itiName.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.selectBatch")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                // disabled
                {...register("batch_of_trade", {
                  required: t("traineeDetails.batchRequired"),
                })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value=""> {t("traineeDetails.selectBatch")}</option>
                <option value="2020-2022">2020 - 2022</option>
                <option value="2021-2022">2021 - 2022</option>
              </select>
              {errors.batch_of_trade && (
                <span className="text-red-500 text-xs">
                  {errors.batch_of_trade.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.selectTrade")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                // disabled
                {...register("name_of_trade", {
                  required: t("traineeDetails.tradeRequired"),
                })}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value="">{t("traineeDetails.selectTrade")}</option>
                {tradeList.map((trade) => {
                  return (
                    <option key={trade} value={trade}>
                      {trade}
                    </option>
                  );
                })}
              </select>
              {errors.name_of_trade && (
                <span className="text-red-500 text-xs">
                  {errors.name_of_trade.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.selectIndustry")}{" "}
                <span className="text-red-400">*</span>
              </label>
              <select
                // disabled
                {...register("name_of_industry_partner", {
                  required: t("traineeDetails.industryRequired"),
                })}
                onChange={(e) => getCoordinatesFromMapbox(e.target.value)}
                className="border rounded-md px-3 py-2 text-sm w-full outline-none disabled:bg-gray-50 "
              >
                <option value="">{t("traineeDetails.selectIndustry")}</option>
                {industryList?.map((ind) => {
                  return (
                    <option key={ind} value={ind}>
                      {ind}
                    </option>
                  );
                })}
              </select>
              {errors.name_of_industry_partner && (
                <span className="text-red-500 text-xs">
                  {errors.name_of_industry_partner.message}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.uploadProfileImage")}
                <span className="text-red-400">*</span>{" "}
              </label>

              <div className="font-[sans-serif]">
                <input
                  type="file"
                  {...register("image_url", {
                    required: t("validation.imageRequired"),
                  })}
                  accept=".jpg,.jpeg,.png,.webp"
                  className="w-full text-gray-500 font-medium text-sm bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:mr-4 file:bg-primary file:hover:bg-primary/90 file:text-white rounded"
                  onChange={handleFileChange}
                />
              </div>
              {fileError && (
                <span className="text-red-500 text-xs">{fileError}</span>
              )}

              {errors.image_url && (
                <span className="text-red-500  text-xs">
                  {t("validation.imageRequired")}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <label className="font-[400] text-xs text-gray-600">
                {t("traineeDetails.isInformationCorrect")}
                <span className="text-red-400">*</span>
              </label>
              <div className="flex mt-1.5 text-gray-500 gap-2 custom-radio">
                <label className="flex items-center gap-1">
                  <input
                    readOnly
                    // disabled
                    type="radio"
                    value="Yes"
                    {...register("isInformationCurrect", {
                      required: t("traineeDetails.isInformationCorrect"),
                    })}
                  />
                  {t("traineeDetails.yes")}
                </label>
                <label className="flex items-center gap-1">
                  <input
                    readOnly
                    // disabled
                    type="radio"
                    value="No"
                    {...register("isInformationCurrect", {
                      required: t("traineeDetails.isInformationCorrect"),
                    })}
                  />
                  {t("traineeDetails.no")}
                </label>
              </div>
              {errors.isInformationCurrect && (
                <span className="text-red-500 text-xs">
                  {errors.isInformationCurrect.message}
                </span>
              )}
            </div>
          </div>
          <div className=" w-full mt-6 flex justify-center items-center space-x-2  ">
            <Button
              className="w-auto px-[10px] font-normal"
              title={t("traineeDetails.recordAttendance")}
              type="submit"
            />
          </div>
        </form>
      </div>
      <Modal
        isOpen={openModal}
        title={t("traineeDetails.currentLocation")}
        onClose={() => setOpenModal(false)}
      >
        {/* data={{latitude :28.6790,longitude:77.1684}}  */}
        <CurrentLocation handleSave={recordTraineeAttendance} data={details} />
      </Modal>
    </div>
  );
};

export default TraineeDetails;
