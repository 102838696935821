import React from "react";
import ButtonIcon from "src/components/custom/ButtonIcon";
import Navbar from "src/components/navbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const userIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
    <path
      fillRule="evenodd"
      d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
      clipRule="evenodd"
    />
  </svg>
);

const LoginLayout = () => {
  const { t } = useTranslation(); // Access the translation function
  const navigate = useNavigate();

  return (
    <div className="w-full h-full">
      <Navbar />
      <div className="h-screen flex flex-col items-center justify-center md:flex-row">
        <ButtonIcon
          onClick={() => navigate("/Trainee-login")}
          className="p-1 w-[190px]"
          icon={userIcon}
          title={t("login.asTrainee")} // Use translation key
        />
        <ButtonIcon
          onClick={() => navigate("/ITI-login")}
          className="p-1 w-[190px]"
          icon={userIcon}
          title={t("login.asITI")} // Use translation key
        />
      </div>
    </div>
  );
};

export default LoginLayout;
